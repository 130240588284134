import React, {ChangeEvent, useCallback, useContext, useState} from "react";
import {Form, InputGroup} from "react-bootstrap";
import DealerListFilters from "./DealerListFilters";
import ScrollList from "./ScrollList";
import {Autocomplete} from "@react-google-maps/api";
import GeoLocationServiceButton from "./GeoLocationServiceButton";
import { DealerLocatorContext, DealerLocatorContextType } from '../../../contexts/DealerLocatorContext'

interface  DealerListViewProps {}

const DealerListView = (props: DealerListViewProps) => {
    const {
        mutableList,
        onSearch,
        onPlaceChanged,
    } = useContext<DealerLocatorContextType>(DealerLocatorContext);

    const [ac, setAC] = useState<google.maps.places.Autocomplete | null>(null);

    const onSearching = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        const value: string = event.target.value.toLowerCase().trim();
        onSearch(value);
    }, [mutableList]);

    const onAutoCompleteLoad = useCallback((ac: google.maps.places.Autocomplete): void => {
        setAC(ac);
    }, []);

    const onAutoCompleteUnmount = useCallback((ac: google.maps.places.Autocomplete): void => {
        setAC(null);
    }, []);

    return (
        <div className="dealers-list-view">
            <Autocomplete
                onPlaceChanged={() => onPlaceChanged(ac)}
                onLoad={onAutoCompleteLoad}
                onUnmount={onAutoCompleteUnmount}
            >
                <>
                    <Form.Label htmlFor="basic-url" className="fw-bold">Search Dealers or Locations</Form.Label>
                    <InputGroup className="mb-0">
                        <InputGroup.Text id="basic-addon3">
                            <i className="fa-solid fa-magnifying-glass"></i>
                        </InputGroup.Text>
                        <Form.Control
                            id="basic-url"
                            aria-describedby="basic-addon3"
                            placeholder="Search a location or dealer."
                            onInput={onSearching}
                        />
                    </InputGroup>
                </>
            </Autocomplete>
            <GeoLocationServiceButton />
            <DealerListFilters />
            <ScrollList />
        </div>
    );
}

export default DealerListView;