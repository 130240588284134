import React, {useEffect, useState} from "react"
import {Button, Form} from "react-bootstrap";
import BrandLogo from "../../../assets/images/tge.jpeg";
import SampleRetailers from "../../../assets/sample_retailers.json";

interface LoginFormProps {}

const LoginForm = (props: LoginFormProps) => {
    const [userName, setUserName] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onUserNameInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value: string = event.target.value;
        setUserName(value);
    }

    const onPasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value: string = event.target.value;
        setPassword(value);
    }

    const onSignInClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        if (userName !== null && password !== null) {
            setLoading(true);
            //TODO: set API call to authenticate user, for now just simulate a timeout
            setTimeout((): void => {
                console.log('signed in');
                //Cache retailers data
                localStorage.setItem('retailers', JSON.stringify(SampleRetailers));
                setLoading(false);
            }, 3000);
        }
    }

    useEffect((): void => {
        console.log({username: userName, password: password});
    }, [userName, password]);

    return (
        <div className="login-form flex-grow-1 p-4">
            <div className="form-image mb-1 text-center">
                <img className="form-heading-logo" src={BrandLogo} alt="The Great Escape"/>
            </div>
            <div className="form-heading mb-5">
            <h1>Log In</h1>
                <p>to start searching a dealer.</p>
            </div>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Username</Form.Label>
                    <Form.Control onChange={onUserNameInputChange} type="text" placeholder="Enter username" />
                    <Form.Text className="text-muted">
                        We'll never share your username with anyone else.
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control onChange={onPasswordInputChange} type="password" placeholder="Password" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 p-3" onClick={onSignInClick}>
                    <span className="pe-3 fw-bold">Sign In</span>
                    <i className="fa-solid fa-arrow-right-to-bracket"></i>
                </Button>
            </Form>
        </div>
    );
}

export default LoginForm;