import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import RetailerLocation from "../../../models/RetailerLocation";
import TooltipButton from "../general/TooltipButton";
import React, {useContext} from "react";
import {DealerLocatorContext, DealerLocatorContextType} from "../../../contexts/DealerLocatorContext";
import Phone from "../../../models/Phone";

interface DealerListItemProps {
    location: RetailerLocation,
    onClick: (location: RetailerLocation | null) => void
}

const DealerListItem = (props: DealerListItemProps) => {
    const { product, sku }: { product: string | null, sku: string | null } = useContext<DealerLocatorContextType>(DealerLocatorContext);
    const address: string | null = props.location?.location.address_1;
    const city: string | null = props.location?.location.city;
    const state: string | null = props.location?.location.state;
    const name: string | null = `${city}, ${state}`;
    const postal_code: string | null = props.location?.location.postal_code;
    const directionsURL: string = props.location.getGoogleMapsDirectionsURL();
    const distance: { value: number | null, text: string | null } = props.location.location.distance;
    const duration: { value: number | null, text: string | null } = props.location.location.duration;
    const phones: Phone [] | null = props.location.location.phones;

    const dealerPageURL = (): string => {
        if (product && sku) {
            const base: string = props.location.getDealerPageRoute();
            return `${base}?product=${product}&sku=${sku}`;
        } else {
            return props.location.getDealerPageRoute();
        }
    }

    return (
        <div className="search-result-item position-relative py-3 cursor-pointer" onClick={() => props.onClick(props.location)}>
            {distance.text && (
                <div className="distance-duration-label position-absolute top-0 end-0 text-end">
                    <small className="d-block p-1 fw-bold">
                        {distance.text}
                    </small>
                </div>
            )}
            <Row>
                <Col>
                    <span className="h6 fw-bold d-block">{name}</span>
                    <span className="d-block p-0 cap">{address}</span>
                    <span className="d-block p-0">
                        <span>{city}</span>,
                        <span className="px-1">{state}</span>
                        <span>{postal_code}</span>
                        {duration.text && (
                            <small className="d-block mt-2">Duration: {duration.text}</small>
                        )}
                    </span>
                    <div className="d-block p-0 text-decoration-none mt-3">
                        <Link
                            className="text-decoration-none me-3 btn btn-outline-dark"
                            to={dealerPageURL()}
                        >
                            View Store
                        </Link>
                        <div className="d-inline-block">
                            <div className="tooltip-button-wrapper mini d-inline-block">
                                <TooltipButton
                                    title="Directions"
                                    link={directionsURL}
                                    icon="fa-solid fa-map"
                                />
                            </div>
                            <div className="mx-2 d-inline-block"/>
                            {(phones[0].phone_number) && (
                                <div className="tooltip-button-wrapper mini d-inline-block">
                                    <TooltipButton
                                        title="Phone"
                                        link={`tel:${phones[0].phone_number}`}
                                        icon="fa-solid fa-phone"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
                <Col xs={2} className="d-flex justify-content-center align-items-center">
                    <div className="btn list-item-arrow-btn">
                        <i className="fa-solid fa-arrow-right"/>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default DealerListItem;