import React, {createContext} from "react";

export interface DealerScreenContextType {
    product: string | null,
    sku: string | null,
    site: string | null,
}

export const DealerScreenContext: React.Context<DealerScreenContextType> = createContext<DealerScreenContextType>({
    product: null,
    sku: null,
    site: null
});