import Retailer from "../../../models/Retailer";
import RetailerLocation from "../../../models/RetailerLocation";
import SharePageSection from "./SharePageSection";

interface DealerHeadingProps {
    retailer: Retailer | null,
    locations: RetailerLocation [],
}

const DealerHeading = (props: DealerHeadingProps) => {
    return (
        <div className="dealer-heading">
            <small>Store</small>
            <h1>{props.retailer?.name}</h1>
            <div className="d-inline-block">
                <div className="me-4 badge primary-color-bg">
                    {props.locations.length > 1 ? 'Multi-Location Store' : 'Approved Dealer'}
                </div>
                <span>
                    {props.retailer?.addresses.length ?? 1}{' '}
                    Store Location{props.locations.length > 1 ? 's' : ''}
                </span>
            </div>
            <div className="my-3">
                <SharePageSection />
            </div>
        </div>
    );
}

export default DealerHeading;