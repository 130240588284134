import Address from "./Address";
import Retailer from "./Retailer";
import handleize from "../helpers/HandleizeHelper";

class RetailerLocation {
    //<editor-fold desc="Members">
    retailer: Retailer;
    location: Address;

    constructor(retailer: Retailer, location: Address) {
        this.retailer = retailer;
        this.location = location;
    }
    //</editor-fold>

    //<editor-fold desc="Functions">
    flatten(): string {
        const name: string | undefined = this.retailer.name?.trim();
        const result = `${name}.${this.location.flatten()}`.toLowerCase();
        return result;
    }

    getDealerPageRoute(): string {
        const dealer: string = handleize(this.retailer?.name ?? "");
        const dealerId: number | null = this.retailer.id;
        const addressId: string | undefined = this.location.id?.toString();
        return `/${dealer}/${dealerId}/location/${addressId}`;
    }

    getGoogleMapsDirectionsURL(): string {
        const baseUrl: string = 'https://www.google.com/maps/search/?api=1';
        const fullAddress: string = `${this.location.address_1}, ${this.location.city}, ${this.location.state}`;
        const encodedAddress: string = encodeURIComponent(fullAddress!);
        return `${baseUrl}&query=${encodedAddress}`;
    }
    //</editor-fold>
}

export default RetailerLocation;