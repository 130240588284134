import Phone from "../../../models/Phone";

interface PhonesSectionProps {
    phones: Phone [] | null | undefined
}

const PhonesSection = (props: PhonesSectionProps) => {
    return props.phones !== null || props.phones !== undefined ? (
        <div className="phones-section">
            {props.phones?.map((p: Phone, i: number) => (
                <div className="phone-block m-1">
                    <small>{i === 0 ? 'Primary Phone No.' : 'Additional Phone No.'}</small>
                    <a href={`tel:${p.phone_number}`} className="text-decoration-none">
                        <span className="fw-bold d-block">{p.phone_number}</span>
                    </a>
                </div>
            ))}
        </div>
    ) : <></>;
}

export default PhonesSection;