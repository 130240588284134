class ContactUrl {
    id: number | null = null;
    type: string | null = null;
    url: string | null = null;

    constructor(data: any) {
        this.id = data.id;
        this.type = data.type;
        this.url = data.url;
    }
}

export default ContactUrl;