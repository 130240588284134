import React, {useContext, useEffect, useState} from "react";
import GeoServiceHelper from "../../../helpers/GeoServiceHelper";
import CustomGeoLocation from "../../../models/CustomGeoLocation";
import Spinner from "react-bootstrap/Spinner";
import {DealerLocatorContext} from "../../../contexts/DealerLocatorContext";

interface GeoLocationServicesButton {}

const GeoLocationServiceButton = (props: GeoLocationServicesButton) => {
    const { onEnableLocationServices } = useContext(DealerLocatorContext);
    const [status, setStatus] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [state, setState] = useState<string | null>(null);
    const [city, setCity] = useState<string | null>(null);

    useEffect(() => {
        setStatus(!!navigator.geolocation);
        const data: string | null = localStorage.getItem("currentPosition");
        //if we have a saved position in cache, use that to load the info quickly
        if (data !== null) {
            const geolocation: CustomGeoLocation = JSON.parse(data);
            setState(geolocation.state);
            setCity(geolocation.city);
        } else {
            //otherwise, check if we're permitted and if so, get the user's location, cache it, and update state
            onClick();
        }
    }, []);

    const onClick = (): void => {
        if (navigator.geolocation) {
            setLoading(true);
            navigator.geolocation.getCurrentPosition(
                async (position: GeolocationPosition) => {
                    const pos: { lat: number, lng: number } = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    //Save location to cache
                    localStorage.setItem("currentPosition", JSON.stringify({...pos}));
                    if (process.env.REACT_APP_USE_GEOCODING_SERVICE === 'true') {
                        const { city, state }: { city: google.maps.GeocoderAddressComponent | null, state: google.maps.GeocoderAddressComponent | null } = await getCurrentCityStateLocation(pos);
                        setCity(city?.long_name ?? null);
                        setState(state?.short_name ?? null);
                        localStorage.setItem("currentPosition", JSON.stringify({
                            city: city?.long_name,
                            state: state?.short_name,
                            ...pos
                        }));
                    }

                    onEnableLocationServices();
                    setLoading(false);
                },
                (error: GeolocationPositionError): void => {
                    console.warn('Geolocation blocked.', error);
                    setStatus(false);
                    setLoading(false);
                }
            );
        }
    }

    //Use Geocoder service helper with the current user coordinates to get their city and state data.
    const getCurrentCityStateLocation = async (position: { lat: number, lng: number }) => {
        return await GeoServiceHelper.getUserStateCity(position.lat, position.lng);
    }

    return (
        <div className="current-location-section mb-3 d-flex justify-content-end">
            <button onClick={onClick} className="current-location btn primary-color-text text-decoration-none">
                <i className={`fa-solid ${status ? "fa-location-arrow" : "fa-location-crosshairs"} me-2`}></i>
                {status ? (
                    <span>
                        {(state !== null && state !== undefined)  && city !== null && city !== undefined ? `${city}, ${state}` : "Using Current Location."}
                        {loading && <Spinner className="ms-2 geo-mini-spinner" animation="border" size="sm" />}
                    </span>
                ) : (
                    <span>Use Current Location.</span>
                )}
            </button>
        </div>
    );
}

export default GeoLocationServiceButton;