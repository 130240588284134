import React from "react";

interface MobileViewTogglerProps {
    view: string | null,
    setView: (view: string) => void
}

const MobileViewToggler = (props: MobileViewTogglerProps) => {
    return (
        <div className="mobile-toggle-views p-2 text-end">
            <div className="d-inline-block w-100">
                <h3 className="text-start d-inline-block">Dealer Locator</h3>
            </div>
            <div className="d-inline-block">
                <button
                    className={`btn toggle-view-btn ${props.view === "list" || props.view === null ? "active" : ""}`}
                    onClick={(e) => props.setView("list")}
                >
                    <i className="fa-solid fa-table-list"></i>
                </button>
                <div className="mx-1 d-inline-block"/>
                <button
                    className={`btn toggle-view-btn ${props.view === "map" ? "active" : ""}`}
                    onClick={(e) => props.setView("map")}
                >
                    <i className="fa-solid fa-map-location-dot"></i>
                </button>
            </div>
        </div>
    );
}

export default MobileViewToggler;