class Geography {
    latitude: number | null = null;
    longitude: number | null = null;

    constructor(data: any) {
        this.latitude = data.latitude;
        this.longitude = data.longitude;
    }

    toJSON(): { lat: number, lng: number } {
        return {
            lat: this.latitude!,
            lng: this.longitude!
        }
    }
}

export default  Geography;