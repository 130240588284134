interface SkeletonProps {
    show?: boolean;
}

const Skeleton = (props: SkeletonProps) => {
    return props.show ?? false ? (
        <div id="skeleton">
            <div className="wrapper">
                <div className="element box skeleton-listview" data-id="0"/>
                <div className="element box skeleton-map" data-id="1"></div>
            </div>
        </div>
    ) : <></>;
}

export default Skeleton;