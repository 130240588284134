import RetailerLocation from "../../../models/RetailerLocation";
import DealerListItem from "./DealerListItem";
import Spinner from "react-bootstrap/Spinner";
import React, {useContext, useEffect, useRef, useState} from "react";
import {DealerLocatorContext, DealerLocatorContextType} from "../../../contexts/DealerLocatorContext";

interface  ScrollListProps {}

const ScrollList = (props: ScrollListProps) => {
    const { mutableList, onDealerListItemClick } = useContext<DealerLocatorContextType>(DealerLocatorContext);
    const PageSize = 20;
    const searchListRef = useRef<HTMLDivElement>(null);
    const [paginatedList, setPaginatedList] = useState<RetailerLocation []>(mutableList)
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect((): void => {
        setPaginatedList(mutableList.slice(0, PageSize));
    }, [mutableList]);

    useEffect(() => {
        const container: HTMLDivElement | null = searchListRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return (): void => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    ///Scrolling handler for list view - paginate by 50 after reachign the end of the scroll
    const handleScroll = (): void => {
        const container: HTMLDivElement | null = searchListRef.current;
        if (container) {
            const isAtBottom: boolean = container.scrollHeight - container.scrollTop <= container.clientHeight + 1;

            if(isAtBottom && ( paginatedList.length < mutableList.length )) {
                setPage((prevPage: number) => prevPage + 1);
            }
        }
    };

    useEffect((): void => {
        const fetchData = async () => {
            setLoading(true);
            // Simulate loading delay
            await new Promise((resolve) => setTimeout(resolve, 1000));
            const startIndex: number = (page - 1) * PageSize;
            const endIndex: number = startIndex + PageSize;
            const newData: RetailerLocation [] = mutableList.slice(startIndex, endIndex)
            setPaginatedList((prevItems: RetailerLocation []) => [...prevItems, ...newData]);
            setLoading(false);
        };

        if (page > 1) {
            fetchData();
        }
    }, [page]);

    return (
        <div className="search-list p-1 bg-white" ref={searchListRef} onScroll={handleScroll}>
            {paginatedList.map((r: RetailerLocation, i: number) => (
                <DealerListItem
                    key={i}
                    location={r}
                    onClick={onDealerListItemClick}
                />
            ))}
            {loading && (
                <div className="d-flex justify-content-center">
                    <Spinner animation="grow"/>
                </div>
            )}
            {mutableList.length === 0 && (
                <div className="d-flex justify-content-center align-items-center">
                    No Dealers Found.
                </div>
            )}
        </div>
    )
}

export default ScrollList;