import React from "react";
import {Container} from "react-bootstrap";
import LoginForm from "../ui/auth/LoginForm";

interface LoginScreenProps {}

const LoginScreen = (props: LoginScreenProps) => {
    return (
        <Container>
            <div className="auth-screen-layout d-flex justify-content-center align-items-center vh-100">
                <LoginForm />
            </div>
        </Container>
    )
}

export default LoginScreen;