import React, {useEffect, useLayoutEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import DealerListView from "../ui/dealer-locator/DealerListView";
import DealerMap from "../ui/dealer-locator/DealerMap";
import NavigationBar from "../ui/general/NavigationBar";
import RetailerLocation from "../../models/RetailerLocation";
import '../../helpers/DateHelper';
import CustomGeoLocation from "../../models/CustomGeoLocation";
import {DealerLocatorContext, DealerLocatorContextType} from "../../contexts/DealerLocatorContext";
import GeoServiceHelper from "../../helpers/GeoServiceHelper";
import {useLocation} from "react-router-dom";
import MobileViewToggler from "../ui/dealer-locator/MobileViewToggler";

interface DealerLocatorScreenProps { locations: RetailerLocation [] }

const DealerLocatorScreen = (props: DealerLocatorScreenProps) => {
    const location = useLocation();
    const [allLocations, setAllLocations] = useState<RetailerLocation []>([])
    const [selectedLocation, setSelectedLocation] = useState<RetailerLocation | null>(null);
    const [zoom, setZoom] = useState<number>(10);
    const [mutableList, setMutableList] = useState<RetailerLocation [] >([]);
    const [center, setCenter] = useState<{lat: number, lng: number} | null>(null);
    const [userLocation, setUserLocation] = useState<{lat: number, lng: number} | null>(null);
    const [mobileView, setMobileView] = useState<string | null>(null);

    //URLParams
    const [product, setProduct] = useState<string | null>(null);
    const [site, setSite] = useState<string | null>(null);
    const [sku, setSku] = useState<string | null>(null);

    useEffect((): void => {
        if (props.locations) {
            const data: RetailerLocation [] = props.locations;
            setAllLocations(data);

            const closestStores: RetailerLocation [] | null = findClosestStoresByStateAndCity();
            closestStores ? setMutableList(closestStores) : setMutableList(data);
        }
    }, [props.locations]);

    useLayoutEffect((): void => {
        if (props.locations) {
            const data: RetailerLocation [] = props.locations;
            setAllLocations(data);

            const closestStores: RetailerLocation [] | null = findClosestStoresByStateAndCity();
            closestStores ? setMutableList(closestStores) : setMutableList(data);

            onEnableLocationServices();
            calculateDistancesOfLocations();
        }
    }, [props.locations]);

    useEffect((): void => {
        const closestStores: RetailerLocation [] | null = findClosestStoresByStateAndCity();

        if (closestStores) {
            setMutableList(closestStores);
        }

    }, [allLocations]);

    useEffect((): void => {
        calculateDistancesOfLocations();
    }, [center]);

    useEffect((): void => {
        const geolocation: CustomGeoLocation | null = getGeoLocation();
        if (geolocation) {
            //Algonquin, IL
            let center: { lat: number, lng: number } = { lat: 42.162741, lng: -88.302571 };
            setCenter(center);

            setUserLocation({ lat: geolocation.lat!, lng: geolocation.lng! })
        }

        const params: URLSearchParams = new URLSearchParams(location.search);
        const product: string | null = params.get("product");
        const site: string | null = params.get("site");
        const sku: string | null = params.get("sku");

        setProduct(product);
        setSite(site);
        setSku(sku);
    }, []);

    const findClosestStoresByStateAndCity = (): RetailerLocation [] | null => {
        const geolocation: CustomGeoLocation | null = getGeoLocation();
        if (geolocation) {
            //Use the current location's state/city data to filter through the list of dealers closest to that location.
            //Then sort the same list by matching city first.
            return [...allLocations]
                .sort((a: RetailerLocation, b: RetailerLocation): number => {
                    // Place objects with the target city first
                    if (a.location.state === geolocation.state && b.location.state !== geolocation.state) {
                        return -1; // `a` comes before `b`
                    } else if (a.location.state !== geolocation.state && b.location.state === geolocation.state) {
                        return 1; // `b` comes before `a`
                    } else {
                        return 0; // Maintain current order
                    }
                })
                .sort((a: RetailerLocation, b: RetailerLocation): number => {
                    // Place objects with the target city first
                    if (a.location.city === geolocation.city && b.location.city !== geolocation.city) {
                        return -1; // `a` comes before `b`
                    } else if (a.location.city !== geolocation.city && b.location.city === geolocation.city) {
                        return 1; // `b` comes before `a`
                    } else {
                        return 0; // Maintain current order
                    }
                });
        } else {
            return null;
        }
    }

    /*
    * AutoComplete Place Change listener, this will grab the geocode, state, city and mutate the current dealer list to
    * show dealers within this place/area/location.
    *
    * It will also fire the props.onPickingAutoCompleteLocation() to update the google map's center position to this place
    * instead.
    *
    * */
    const onPlaceChanged = (ac: google.maps.places.Autocomplete | null): void => {
        if (ac) {
            const place: google.maps.places.PlaceResult = ac?.getPlace();
            if (place !== undefined) {
                const lat: number | undefined = place?.geometry?.location?.lat();
                const lng: number | undefined = place?.geometry?.location?.lng();
                const {city, state}: { city: google.maps.GeocoderAddressComponent | null, state: google.maps.GeocoderAddressComponent | null } = GeoServiceHelper.pluckStateCityFromAddressComponent(place.address_components);
                if (lat !== undefined && lng !== undefined) {
                    setCenter({ lat: lat, lng: lng });
                    setZoom(15);
                }

                const closestStores: RetailerLocation [] = filterLocationsByCurrentCityAndState(state?.short_name, city?.long_name);
                setMutableList(closestStores);
                setSelectedLocation(null);
            }
        }
    }

    //Use the selected place's state/city data to filter through the list of dealers closest to that location.
    //Then sort the same list by matching city first.
    const filterLocationsByCurrentCityAndState = (state: string | undefined | null, city: string | undefined | null): RetailerLocation [] => {
        if (state && city) {
            return allLocations
                .filter((i: RetailerLocation): boolean => i.location.state === state)
                .sort((a: RetailerLocation, b: RetailerLocation): number => {
                    // Place objects with the target city first
                    if (a.location.city === city && b.location.city !== city) {
                        return -1; // `a` comes before `b`
                    } else if (a.location.city !== city && b.location.city === city) {
                        return 1; // `b` comes before `a`
                    } else {
                        return 0; // Maintain current order
                    }
                }
            );
        } else {
            return [];
        }
    }

    ///Dealer List Item click event handler, set the selected location to state
    const onDealerListItemClick = (location: RetailerLocation | null): void => {
        setSelectedLocation(location);
    }

    ///State and City Filter element will call this function after its filtered the list by these properties, set to mutable list
    const onFilteredLocations = (results: RetailerLocation []): void => {
        setMutableList(results);
    }

    ///Loop through the stores, use this distance formula to calculate the distance for each store location to the current user location
    const calculateDistancesOfLocations = (): void => {
        if (userLocation) {
            let stores: RetailerLocation [] = allLocations;
            stores = stores.map((store: RetailerLocation) => {
                const origin: { lat: number, lng: number } = userLocation;
                const destination: { lat: number, lng: number } | undefined = store.location.geography?.toJSON();

                //Formula for calculating distance based on Lng/Lat coordinate values - for reference
                //https://stackoverflow.com/questions/1110565/distance-between-2-geocodes#:~:text=The%20distance%20difference%20in%20the,distance%20if%20the%20latitude%20changes
                let latP1: number = origin?.lat;
                let lngP1: number = origin?.lng;
                let latP2: number = destination?.lat!;
                let lngP2: number = destination?.lng!;

                let d2r: number = Math.PI / 180, R: number = 6371; // Earth Radius in km
                latP1 *= d2r; lngP1 *= d2r; latP2 *= d2r; lngP2 *= d2r; // convert to radians
                let dlat: number = latP2 - latP1, dlng: number = (lngP2 - lngP1) * Math.cos(latP1);
                let distance: number = parseFloat((R * Math.sqrt( dlat*dlat + dlng*dlng )* 0.621371).toFixed(1)); // distance in miles.

                //Set the distance value to this store's distance text and value.
                store.location.distance.value = distance;
                store.location.distance.text = `${distance} mi`;
                return store;
            });

            //Sort from closest to farthest. Then, set this new list to state
            stores.sort((a: RetailerLocation, b: RetailerLocation) => a.location.distance.value! - b.location.distance.value!);
            setMutableList(stores);
            setSelectedLocation(null);
        }
    }

    const getGeoLocation = (): CustomGeoLocation | null => {
        const data: string | null = localStorage.getItem("currentPosition");
        if (data) {
            const geolocation: CustomGeoLocation = JSON.parse(data);
            return geolocation;
        } else {
            return null;
        }
    }

    //Trigger event for when the user approves geolocation services, get the coordinates and set the center position to it.
    const onEnableLocationServices = (): void => {
        const geolocation: CustomGeoLocation | null = getGeoLocation();
        if (geolocation) {

            //Center the position
            setCenter({ lat: geolocation.lat!, lng: geolocation.lng! });

            const stores: RetailerLocation [] | null = findClosestStoresByStateAndCity();
            if (stores && stores.length > 0) {
                setMutableList(stores);
            }

            if (process.env.REACT_APP_USE_GEOCODING_SERVICE === 'true') {
                const input: HTMLInputElement = document.getElementById('basic-url') as HTMLInputElement;
                input.value = `${geolocation.city}, ${geolocation.state}`;
            }
        }
    }

    //Event handler for searching through dealer list view.
    const onSearch = (value: string): void => {
        //If text is cleared, set it back to original prop.items list
        if (value.length === 0) {
            setMutableList(allLocations);
        } else {
            const filtered: RetailerLocation [] = allLocations.filter((e: RetailerLocation) => e.flatten().includes(value));
            setMutableList(filtered);
        }
    }

    //Dealer Locator Screen Context values - shared with state
    const ContextValues: DealerLocatorContextType = {
        allLocations,
        mutableList,
        selectedLocation,
        zoom,
        center,
        product,
        sku,
        site,
        onSearch,
        onPlaceChanged,
        onEnableLocationServices,
        onFilteredLocations,
        onDealerListItemClick
    }

    return (
        <div className="dealer-locator-page vh-100">
            <Container fluid>
                <NavigationBar />
                {product && sku && (
                    <>
                        <h4 className="fw-bold d-inline-block text-end w-100">Provide your address to find the nearest stores that carry</h4>
                        <span className="fw-bold d-block w-100 text-end mb-3 primary-color-text">Item: {product}</span>
                    </>
                )}
                <MobileViewToggler
                    view={mobileView}
                    setView={setMobileView}
                />
                <Row>
                    <Col md="3" className="p-3">
                        <Container fluid className={`h-100 p-0 ${mobileView !== null ? mobileView === "list" ? "" : "d-none" : ""}`}>
                            <DealerLocatorContext.Provider value={ContextValues}>
                                <DealerListView/>
                            </DealerLocatorContext.Provider>
                        </Container>
                    </Col>
                    <Col md className="p-0">
                        <Container fluid className={`w-100 p-0 ${mobileView !== null ? mobileView === "map" ? "d-flex" : "d-none" : "d-flex"} map-container`}>
                            <DealerMap
                                zoom={zoom}
                                center={center}
                                userLocation={userLocation}
                                centerToUserLocation={false}
                                locations={allLocations}
                                selectedLocation={selectedLocation}
                                product={product}
                                sku={sku}
                                site={site}
                            />
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default DealerLocatorScreen;