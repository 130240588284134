class Phone {
    phone_number: string | null = null;

    constructor(data: any) {
        this.phone_number = data;
    }

    //Return string of all properties
    flatten(): string {
        return [this.phone_number].map((e: string | null) => {
            e?.toLowerCase();
            e?.trim();
            return e;
        }).join("|");
    }
}

export default Phone;