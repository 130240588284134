import {Link, useLoaderData} from "react-router-dom";
import {Breadcrumb, Col, Container, Row} from "react-bootstrap";
import NavigationBar from "../ui/general/NavigationBar";
import DealerMap from "../ui/dealer-locator/DealerMap";
import Retailer from "../../models/Retailer";
import {useEffect, useState} from "react";
import Address from "../../models/Address";
import RetailerLocation from "../../models/RetailerLocation";
import StoreInfoSection from "../ui/dealer-screen/StoreInfoSection";
import DealerHeading from "../ui/dealer-screen/DealerHeading";
import {DealerScreenContext, DealerScreenContextType} from "../../contexts/DealerScreenContext";


interface DealerScreenProps {}

interface LoaderData {
    retailer: Retailer,
    addressId: string
}

const DealerScreen = (props: DealerScreenProps) => {
    const mapContainerStyle: { width: string, minHeight: string, position: string } = {
        width: '100%',
        minHeight: '100%',
        position: 'absolute',
    };
    const loader_data: LoaderData = useLoaderData() as LoaderData;
    const [locations, setLocations] = useState<RetailerLocation []>([]);
    const [current_retailer, setCurrentRetailer] = useState<Retailer | null>(null);
    const [current_location, setCurrentLocation] = useState<Address | null>(null);
    const [tab, setTab] = useState<string>("info");

    //URLParams
    const [product, setProduct] = useState<string | null>(null);
    const [site, setSite] = useState<string | null>(null);
    const [sku, setSku] = useState<string | null>(null);

    useEffect((): void => {
        if (loader_data) {
            const { retailer, addressId }: { retailer: Retailer, addressId: string } = loader_data;
            if (retailer) {
                const matching_address: Address | undefined = retailer.addresses.find((a: Address): boolean => a.id === parseInt(addressId));
                setCurrentRetailer(retailer);
                setCurrentLocation(matching_address!);

                const retailLocation: RetailerLocation [] = retailer.addresses.map((a: Address) => new RetailerLocation(retailer, a));
                setLocations(retailLocation);
            }
        }
    }, [loader_data]);

    //Check URL query parameters and use the info on the page
    useEffect((): void => {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        const product: string | null = params.get("product");
        const site: string | null = params.get("site");
        const sku: string | null = params.get("sku");
        setProduct(product);
        setSite(site);
        setSku(sku);
    }, []);

    const onTabClick = (key: string): void => {
        setTab(key);
    }

    const ContextValues: DealerScreenContextType = {
        product: product,
        sku: sku,
        site: site
    }

    return (
        <DealerScreenContext.Provider value={ContextValues}>
            <div className="dealer-page">
                <Container fluid>
                    <NavigationBar/>
                    <Container fluid>
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">
                                <span className="text-decoration-none primary-color-text">Home</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to="/dealer-locator" className="text-decoration-none primary-color-text">
                                    Store Locator
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                <Link to={window.location.href}
                                      className="text-decoration-none primary-color-text fw-bold">
                                    Store
                                </Link>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <DealerHeading
                            retailer={current_retailer}
                            locations={locations}
                        />
                        <div className="map-dealer-info-container mt-5 container-fluid">
                            <Row>
                                {locations.length > 1 && (
                                    <Col md={2}>
                                        <p className="p-0 mb-1 fw-bold">Locations</p>
                                        <div className="address-buttons">
                                            <div className="bg-light rounded-3">
                                                {locations.map((e: RetailerLocation, index: number) => (
                                                    <div key={e.location.id}
                                                         className={`my-2 p-2 fw-bold ${current_location?.address_1 === e.location.address_1 ? 'primary-color-bg' : ''} rounded-3`}>
                                                        <Link
                                                            className={`text-decoration-none ${current_location?.address_1 === e.location.address_1 ? 'text-white' : ''}`}
                                                            to={e.getDealerPageRoute()}>
                                                            {index + 1}. {`${e.location.city}, ${e.location.state}`}
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </Col>
                                )}
                                <Col md>
                                    <div className="dealer-store-section h-100 position-relative">
                                        <div
                                            className="dealer-info-section h-100 border-3 border-light-subtle border bg-white rounded-3">
                                            <Container fluid className="h-100">
                                                <Row className="h-100">
                                                    <Col xs={3} className="p-0 h-100">
                                                        <StoreInfoSection
                                                            picture_alt={current_location?.picture_alt}
                                                            picture_url={current_location?.picture_url}
                                                            addressName={current_location?.address_name}
                                                            address1={current_location?.address_1}
                                                            address2={current_location?.address_2}
                                                            city={current_location?.city}
                                                            state={current_location?.state}
                                                            postalCode={current_location?.postal_code}
                                                            phones={current_location?.phones}
                                                            contactUrls={current_location?.contact_urls}
                                                            directionsUrl={current_location?.getGoogleMapsDirectionsURL()}
                                                        />
                                                    </Col>
                                                    <Col xs>
                                                        <Container fluid className="p-0 map-container w-100 position-relative">
                                                            {(current_location && (
                                                                <DealerMap
                                                                    locations={locations}
                                                                    containerStyle={mapContainerStyle}
                                                                    centerToUserLocation={false}
                                                                    zoom={18}
                                                                    center={{
                                                                        lat: current_location!.geography?.latitude!,
                                                                        lng: current_location!.geography?.longitude!
                                                                    }}
                                                                />
                                                            ))}
                                                        </Container>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Container>
            </div>
        </DealerScreenContext.Provider>
);
}

export default DealerScreen;