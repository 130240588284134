import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {JSX} from "react";

interface  TooltipButtonProps {
    title: string,
    text?: string,
    link: string | JSX.Element,
    icon: string
}

const TooltipButton = (props: TooltipButtonProps) => {
    return (
        <OverlayTrigger overlay={<Tooltip>{props.title}</Tooltip>}>
            {typeof props.link === 'string' ? (
                <a href={props.link} target="_blank" rel="noreferrer">
                    <i className={`${props.icon}`} />
                    {props.text}
                </a>
            ) : (props.link)}
        </OverlayTrigger>
    )
}

export default TooltipButton;