interface LocationInfoBlockProps {
    infoTitle: string,
    infoValue: string | null | undefined
}

const LocationInfoBlock = (props: LocationInfoBlockProps) => {
    return props.infoValue !== null && props.infoValue !== "" ? (
        <div className="location-info-block mb-3">
            <h5>{props.infoTitle}</h5>
            <span className="d-block">{props.infoValue}</span>
        </div>
    ) : <></>;
}

export default LocationInfoBlock;