import React from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import BrandLogo from "../../../assets/images/tge.jpeg";
import MenuIcon from "../../../assets/menu.svg";
const NavigationBar = () => {
    return (
        <Navbar expand="lg" className="bg-body-tertiary mb-2">
            <Container fluid>
                <Navbar.Brand href="https://www.shopthegreatescape.com/">
                    <img
                        alt="The Great Escape"
                        src={BrandLogo}
                        width={250}
                        height={30}
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="border-0">
                    <img className="menu-icon" alt="Menu" src={MenuIcon} />
                </Navbar.Toggle>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link target="_blank" href="https://9fa778-50.myshopify.com/pages/contact">CONTACT</Nav.Link>
                        <Nav.Link target="_blank" href="https://9fa778-50.myshopify.com/pages/commercial-sales">COMMERCIAL SALES</Nav.Link>
                        <Nav.Link target="_blank" href="https://9fa778-50.myshopify.com/pages/shipping-info">SHIPPING INFO</Nav.Link>
                        <Nav.Link target="_blank" href="https://9fa778-50.myshopify.com/blogs/news">DISCOVER BLOG</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBar;