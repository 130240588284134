class GeoServiceHelper {

    static pluckStateCityFromAddressComponent(geo: google.maps.GeocoderAddressComponent [] | undefined): {state: google.maps.GeocoderAddressComponent | null, city: google.maps.GeocoderAddressComponent | null} {
        let data: {city: google.maps.GeocoderAddressComponent | null, state: google.maps.GeocoderAddressComponent | null } = {
            city: null,
            state: null,
        }

        if (geo !== undefined) {
            for (let i: number = 0; i < geo.length; i++) {
                const component: google.maps.GeocoderAddressComponent = geo[i];
                if (component.types.includes('country') || component.types.includes('postal_code')) continue;

                if (component.types.includes('administrative_area_level_1')) {
                    data.state = component;
                } else if (
                    component.types.includes('locality') ||
                    component.types.includes('sublocality') ||
                    component.types.includes('sublocality_level_1')
                ) {
                    data.city = component;
                }
            }
        }

        return data;
    }

    static getUserStateCity(lat: number, lng: number): Promise<{state: google.maps.GeocoderAddressComponent | null, city: google.maps.GeocoderAddressComponent | null}> {
        return new Promise(async (resolve, reject) => {
            const geocoder: google.maps.Geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: { lat, lng } }, (results: google.maps.GeocoderResult [] | null, status: google.maps.GeocoderStatus): void => {
                if (status === 'OK' && results !== null) {
                    const addressComponents: google.maps.GeocoderAddressComponent[] = results[0].address_components;
                    const data: {
                        state: google.maps.GeocoderAddressComponent | null;
                        city: google.maps.GeocoderAddressComponent | null
                    } = this.pluckStateCityFromAddressComponent(addressComponents);
                    resolve(data);
                } else {
                    reject("> GeoServiceHelper: getUserState() was not successful for the following reason:" + status);
                }
            });
        })
    }
}

export default GeoServiceHelper;