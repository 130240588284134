import React from "react";
import {Link} from "react-router-dom";
import RetailerLocation from "../../../models/RetailerLocation";
import TooltipButton from "./TooltipButton";
import Phone from "../../../models/Phone";

interface InfoWindowBubbleProps {
    location: RetailerLocation,
    product?: string | null,
    sku?: string | null,
    site?: string | null,
}

const InfoWindowBubble = (props: InfoWindowBubbleProps) => {
    const address: string | null = props.location?.location.address_1;
    const city: string | null = props.location?.location.city;
    const state: string | null = props.location?.location.state;
    const name: string | null = `${city}, ${state}`;
    const postal_code: string | null = props.location?.location.postal_code;
    const distance: { value: number | null, text: string | null } = props.location.location.distance;
    const duration: { value: number | null, text: string | null } = props.location.location.duration;
    const phones: Phone [] | null = props.location.location.phones;

    const dealerPageUrl = (): string => {
        if (props.product && props.sku) {
            const base: string = props.location.getDealerPageRoute();
            return `${base}?product=${props.product}&sku=${props.sku}`;
        } else {
            return props.location?.getDealerPageRoute();
        }
    }

    return (
        <div className="info-window-bubble mt-2">
            <h5>{name ?? "Dealer"}</h5>
            <span className="d-block mb-1">{address ?? "Address"}</span>
            <span className="d-block">
                {city ?? "City"}, {state ?? "State"} {postal_code ?? "Postal Code"}
            </span>
            <span className="d-block mt-2 fw-bold">Phone(s):</span>
            {phones.map((phone: Phone, key: number) => (
                <span className="d-block mt-2" key={key}>
                    <a className="text-decoration-none text-primary" href={`tel:${phone.phone_number}`}>
                        {phone.phone_number}
                    </a>
                </span>
            ))}
            {(distance.value && duration.value) && (
                <div className="my-3">
                    <span className="d-inline-block me-2">
                        <i className="fa-solid fa-car pe-1"></i>
                        {distance.text}
                    </span>
                    <span className="d-inline-block">
                        <i className="fa-solid fa-clock pe-1"></i>
                        {duration.text}
                    </span>
                </div>
            )}
            <div className="bubble-footer mt-2">
                <Link className="text-decoration-none primary-color-text fw-bold" to={dealerPageUrl()}>View Dealer</Link>
                <div className="float-end">
                    <div className="tooltip-button-wrapper d-inline-block">
                        <TooltipButton
                            title="Directions"
                            link={props.location.getGoogleMapsDirectionsURL()}
                            icon="fa-solid fa-map"
                        />
                    </div>
                    <div className="mx-2 d-inline-block"/>
                    {(phones[0].phone_number) && (
                        <div className="tooltip-button-wrapper d-inline-block">
                            <TooltipButton
                                title="Phone"
                                link={`tel:${phones[0].phone_number}`}
                                icon="fa-solid fa-phone"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default InfoWindowBubble;