import Geography from "./Geography";
import Phone from "./Phone";
import ContactUrl from "./ContactUrl";

class Address {
    id: number | null = null;
    address_name: string | null;
    type: string | null = null;
    address_1: string | null = null;
    address_2: string | null = null;
    city: string | null = null;
    state: string | null = null;
    postal_code: string | null = null;
    time_zone: string | null = null;
    geography: Geography | null = null;
    phones: Phone [] = [];
    contact_urls: ContactUrl [] = [];
    distance: { value: number | null, text: string | null } = { value: null, text: null };
    duration: { value: number | null, text: string | null } = { value: null, text: null };
    picture_url: string | null | undefined = null;
    picture_alt: string | null | undefined = null;

    constructor(data: any) {
        this.id = data.id;
        this.address_name = data.address_name;
        this.type = data.type;
        this.address_1 = data.address1;
        this.address_2 = data.address2;
        this.city = data.city;
        this.state = data.state;
        this.postal_code = data.postal_code;
        this.time_zone = data.time_zone;
        this.picture_url = data.picture_url;
        this.picture_alt = data.picture_alt;
        this.geography = new Geography(data.geography);
        this.phones = data.phones.map((p: any) => new Phone(p));
        // this.contact_urls = data.contact_urls.map((c: any) => new ContactUrl(c));
    }

    flatten():string {
        return [this.type, this.address_1, this.address_2, this.city, this.state, this.postal_code, this.time_zone].map((e: string | null) => {
            const data: string | null = e;
            data?.trim();
            data?.toLowerCase();
            return data;
        }).join("|");
    }


    /* Create google maps directions url - external link */
    getGoogleMapsDirectionsURL(): string {
        const baseUrl: string = 'https://www.google.com/maps/search/?api=1';
        const fullAddress: string = `${this.address_1}, ${this.city}, ${this.state}`;
        const encodedAddress: string = encodeURIComponent(fullAddress!);
        return `${baseUrl}&query=${encodedAddress}`;
    }
}

export default  Address;