import RetailerLocation from "../models/RetailerLocation";
import React, {createContext} from "react";

export interface DealerLocatorContextType {
    allLocations: RetailerLocation []
    selectedLocation: RetailerLocation | null,
    zoom: number,
    mutableList: RetailerLocation [],
    center: {lat: number, lng: number} | null,
    product: string | null,
    sku: string | null,
    site: string | null,
    onSearch: (value: string) => void,
    onPlaceChanged: (ac: google.maps.places.Autocomplete | null) => void,
    onEnableLocationServices: () => void,
    onFilteredLocations: (results: RetailerLocation []) => void;
    onDealerListItemClick: (location: RetailerLocation | null) => void
}

export const DealerLocatorContext: React.Context<DealerLocatorContextType> = createContext<DealerLocatorContextType>({
    allLocations: [],
    mutableList: [],
    selectedLocation: null,
    zoom: 10,
    center: null,
    product: null,
    sku: null,
    site: null,
    onSearch: (): void => {},
    onPlaceChanged: (): void => {},
    onEnableLocationServices: (): void => {},
    onFilteredLocations: (): void => {},
    onDealerListItemClick: (): void => {}
});
