const handleize = function (value: string): string {
    // Convert special characters to normal characters
    let convertedString: string = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // Replace spaces with hyphens and convert to lowercase
    convertedString = convertedString.replace(/\s+/g, '-').toLowerCase();

    // Remove any characters that are not alphanumeric or hyphens
    convertedString = convertedString.replace(/[^a-z0-9-]/g, '');

    return convertedString;
}

export default handleize;