import React, {useEffect, useState} from 'react';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider, Params
} from "react-router-dom";
import LoginScreen from "./components/layouts/LoginScreen";
import DealerLocatorScreen from "./components/layouts/DealerLocatorScreen";
import DealerScreen from "./components/layouts/DealerScreen";
import Retailer from "./models/Retailer";
import RetailerLocation from "./models/RetailerLocation";
import SampleRetailers from "./assets/sample_retailers.json";
import {Libraries, useJsApiLoader} from "@react-google-maps/api";
import Skeleton from "./components/layouts/Skeleton";
const libraries: Libraries = ['places']

function App() {
    const [retailers, setRetailers] = useState<Retailer []>([]);
    const [retailLocations, setRetailLocations] = useState<RetailerLocation []>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const { isLoaded, loadError }: {
        isLoaded: boolean,
        loadError: Error | undefined
    } = useJsApiLoader({
        id: process.env.REACT_APP_GOOGLE_MAP_ID,
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
        libraries
    });

    useEffect((): void => {
        (async () => {
            const signedIn: boolean = true;
            if (signedIn) {
                const data: Retailer [] = await getRetailers();
                let locations: RetailerLocation [] = [];
                data.forEach((r: Retailer): void => {
                    const retailerLocations: RetailerLocation [] = r.addresses.map((a) => new RetailerLocation(r, a));
                    locations = [...locations, ...retailerLocations];
                });

                setRetailers(data);
                setRetailLocations(locations);
            }
        })();
    }, []);

    ///Handler for getting retailer data, checks to see if the data has gone more than 24 hours old to request new data again, or read from cache.
    const getRetailers = (): Promise<Retailer []> => {
        return new Promise( async (resolve, reject) => {
            try {
                // const lastUpdated: string | null = localStorage.getItem('last_updated_retailers');
                // if (lastUpdated !== null) {
                //     const timestamp: number = parseInt(lastUpdated);
                //     const refresh_data: boolean = Date.prototype.hasItBeenMoreThan24Hours(timestamp);
                //
                //     if (refresh_data) {
                //         //Run axios call for data
                //         // console.log('data got old - time to refresh');
                //         const retailers: Retailer [] = await fetchRetailerData();
                //         resolve(retailers);
                //     } else {
                //         //Read from cache
                //         // console.log('reading from cache because the data is still new');
                //         const cache_retailers: string | null = localStorage.getItem('retailers');
                //         const json = JSON.parse(cache_retailers || "");
                //         const retailers: Retailer [] = json.retailers.map((r: any) => new Retailer(r));
                //         resolve(retailers);
                //     }
                // } else {
                //     //Run axios call for data
                //     // console.log('no lastupdated');
                //     const retailers: Retailer [] = await fetchRetailerData();
                //     resolve(retailers);
                // }
                const retailers: Retailer [] = await fetchRetailerData();
                resolve(retailers);
            } catch (e: any) {
                console.error("Error retrieving retailers:", e);
                setLoading(false);
                reject(e);
            }
        });
    }

    ///Issue an HTTP request for Retailers data
    const fetchRetailerData = async () => {
        setLoading(true);
        const retailers: Retailer[] = SampleRetailers.retailers.map((r: any) => new Retailer(r));
        localStorage.setItem('retailers', JSON.stringify(SampleRetailers));
        localStorage.setItem('last_updated_retailers', new Date().getTime().toString());
        setLoading(false);
        return retailers;
    }

    const dealerScreenLoader = ({ params }: { params: Params }) => {
        const { dealerId, addressId } = params;
        const retailer: Retailer | undefined = retailers.find((retailer: Retailer): boolean => retailer.id === parseInt(dealerId!));
        return {
            retailer: retailer,
            addressId: addressId
        };
    }

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
                <Route
                    path="/"
                    element={retailLocations.length > 0 ? <DealerLocatorScreen locations={retailLocations} /> : <></>}
                />
                <Route
                    path="/dealer-locator"
                    element={retailLocations.length > 0 ? <DealerLocatorScreen locations={retailLocations} /> : <></>}
                />
                <Route
                    path="/:dealerHandle/:dealerId/location/:addressId"
                    loader={dealerScreenLoader}
                    element={<DealerScreen />}
                />
                <Route path="/login" element={<LoginScreen />} />
            </Route>
        )
    );

    if (!isLoaded) return <Skeleton show={true} />
    if (loadError) return <div>Error</div>

    return isLoaded ? <RouterProvider router={router} /> : <Skeleton show={!isLoaded} />
}

export default App;
