import { Form } from "react-bootstrap";
import RetailerLocation from "../../../models/RetailerLocation";
import States from "../../../assets/states.json";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import {DealerLocatorContext, DealerLocatorContextType} from "../../../contexts/DealerLocatorContext";
interface DealerListFiltersProps {}

const DealerListFilters = (props: DealerListFiltersProps) => {
    const { allLocations, mutableList, onFilteredLocations } = useContext<DealerLocatorContextType>(DealerLocatorContext);
    const [cities, setCities] = useState<string []>([]);
    const [selectedState, setSelectedState] = useState<string | null>(null);
    const [selectedCity, setSelectedCity] = useState<string | null>(null);

    useEffect((): void => {
        let cities: Set<string> = new Set();
        mutableList.forEach((loc: RetailerLocation): void => {
            if (loc.location.state === selectedState && loc.location.city !== null) {
                cities.add(loc.location.city);
            }
        });
        setCities(Array.from(cities));

    }, [selectedState, mutableList]);

    useEffect((): void => {
        setSelectedCity(null);
    }, [cities]);

    useEffect((): void => {
        generateFilteredResults();
    }, [selectedState, selectedCity]);

    ///Change handler for states - set to state
    const onStateChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const val: string = event.target.value;
        const state: string | null = val !== "State" ? val : null;
        setSelectedState(state);
    }

    ///Change handler for Cities - set to state
    const onCityChange = (event: ChangeEvent<HTMLSelectElement>): void => {
        const val: string = event.target.value;
        const city: string | null = val !== "City" ? val : null;
        setSelectedCity(city);
    }

    const generateFilteredResults = (): RetailerLocation [] => {
        const filtered: RetailerLocation [] = allLocations.filter((loc: RetailerLocation): boolean => {
            if (selectedState !== null && selectedCity !== null) {
                return selectedState === loc.location.state && selectedCity === loc.location.city;
            } else if (selectedState !== null && selectedCity === null) {
                return selectedState === loc.location.state;
            } else {
                return true;
            }
        });

        onFilteredLocations(filtered);
        return filtered;
    }

    const onClear = (): void => {
        setSelectedState(null);
        setSelectedCity(null);
    }

    return (
        <div className="filters mb-2">
            <span className="fw-bold me-2 d-block">Filters</span>
            <div className="mx-auto d-inline-block" />
            <Form.Select value={selectedState ?? "State"} className="d-inline-block filter-group filter-group-state" onChange={onStateChange} aria-label="State">
                <option value="State">State</option>
                {States.states.map((state) => (
                    <option key={state} value={state}>{state}</option>
                ))}
            </Form.Select>
            <div className="mx-1 d-inline-block" />
            <Form.Select className="d-inline-block filter-group filter-group-city" onChange={onCityChange} aria-label="City">
                <option value="City">City</option>
                {cities.map((city) => (
                    <option key={city} value={city}>{city}</option>
                ))}
            </Form.Select>
            {(selectedCity || selectedState) && (
                <button className="btn py-0" onClick={onClear}>
                    <i className="fa-solid fa-circle-xmark me-2"></i>
                    <small>Clear</small>
                </button>
            )}
        </div>
    )
}

export default DealerListFilters;