import React, {useCallback, useEffect, useState} from 'react'
import {GoogleMap, InfoWindow, MarkerClustererF, MarkerF, PolylineF} from '@react-google-maps/api';
import RetailerLocation from "../../../models/RetailerLocation";
import InfoWindowBubble from "../general/InfoWindowBubble";
import CustomGeoLocation from "../../../models/CustomGeoLocation";
import GeoServiceHelper from "../../../helpers/GeoServiceHelper";
import '../../../styles/MapStyle';
import {MapStyle} from "../../../styles/MapStyle";

//Algonquin, IL
let center: { lat: number, lng: number } = { lat: 42.162741, lng: -88.302571 };

//Read from cache and get the user's current position - if available
const data: string | null = localStorage.getItem("currentPosition");
if (data !== null) {
    const geolocation: CustomGeoLocation = JSON.parse(data);
    center = {lat: geolocation.lat!, lng: geolocation.lng!};
}

interface  DealerMapProps {
    locations: RetailerLocation [],
    containerStyle?: any,
    center?: { lat: number, lng: number } | null,
    centerToUserLocation?: boolean,
    userLocation?: { lat: number, lng: number } | null,
    selectedLocation?: RetailerLocation | null;
    zoom?: number,
    product?: string | null,
    sku?: string | null,
    site?: string | null
}

const DealerMap = (props: DealerMapProps) => {

    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [selected_retailer_location, setSelectedRetailerLocation] = useState<RetailerLocation | null>(null);
    const [current_position, setCurrentPosition] = useState<any | null>(null);
    const [directions, setDirections] = useState<any>(null);

    const defaultContainerStyle = { width: '100%', minHeight: '100%' };
    const defaultZoomLevel: number = 10;
    const userMarkerOptions = {
        icon: {
            path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            fillColor: '#F0C442', // Set the color here
            borderWidth: 10,
            borderColor: 'red',
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 10, // Adjust the size of the marker
        },
        label: {
            text: 'Current',
            color: '#000', // Label text color
            backgroundColor: '#FFF',
            borderRadius: 10,
            fontSize: '16px', // Label font size
            fontWeight: 'bold', // Label font weight
        },
    };
    const mapOptions = { styles: MapStyle }
    const polylineOptions = {
        geodesic: true,
        strokeColor: '#FF0000', // Line color
        strokeOpacity: 1.0, // Line opacity
        strokeWeight: 4, // Line thickness
    }
    const markerOptions = { label: { text: ' ', color: '#fff' }}

    useEffect((): void => {
        if (props.selectedLocation !== null && props.selectedLocation !== undefined) {
            onMarkerClick(props.selectedLocation)
        }
    }, [props.selectedLocation]);

    ///This listens to changes in the selected location and recalculates the driving route while zooming into the marker
    useEffect((): void => {
        if (props.selectedLocation) {
            const origin: { lat: number, lng: number } = props.center ?? center;
            const destination: { lat: number, lng: number } = props.selectedLocation?.location.geography!.toJSON();
            createRouteBetweenMarkers(origin, destination!);
        } else {
            //if selected location was set to null, set the directions to null as well
            setDirections(null);
        }
    }, [props.locations, props.center, props.selectedLocation]);

    ///Map loader callback to retrieve map instance
    const onMapLoad = useCallback((map: google.maps.Map): void => {
        const doesUserWantToCenter: boolean = props.centerToUserLocation ?? false;
        //Localize map to their location - ask permission first.
        if (navigator.geolocation && doesUserWantToCenter) {
            navigator.geolocation.getCurrentPosition(
                async (position: GeolocationPosition) => {
                    try {
                        const pos: { lat: number, lng: number } = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };

                        localStorage.setItem("currentPosition", JSON.stringify({...pos}));
                        if (process.env.REACT_APP_USE_GEOCODING_SERVICE === 'true') {
                            const { city, state }: { city: google.maps.GeocoderAddressComponent | null, state: google.maps.GeocoderAddressComponent | null } = await GeoServiceHelper.getUserStateCity(pos.lat, pos.lng);

                            //Save to cache
                            localStorage.setItem("currentPosition", JSON.stringify({
                                city: city?.long_name,
                                state: state?.short_name,
                                ...pos
                            }));
                        }

                    } catch (e) {
                        console.log(e);
                    }
                },
                () => console.warn('Geolocation blocked.')
            );
        }

        setMap(map);
    }, []);

    ///Map unmount callback to remove map instance
    const onMapUnmount = useCallback((map: google.maps.Map): void => {
        setMap(null);
    }, []);

    ///Marker Click Handler: triggers marker animations, and InfoWindow popups.
    const onMarkerClick = (retailer_location: RetailerLocation): void => {
        setSelectedRetailerLocation(retailer_location);
        const position: { lat: number, lng: number } | undefined = retailer_location.location.geography?.toJSON();
        if (position !== undefined) {
            //If the user is too zoomed out, readjust the map zoom level.
            if (map?.getZoom()! < 15) { map?.setZoom(15); }
            map?.panTo(position);
            setCurrentPosition(position!);

            const origin: { lat: number, lng: number } = props.center ?? center;
            const destination: { lat: number, lng: number } = retailer_location?.location.geography!.toJSON();
            createRouteBetweenMarkers(origin, destination!);
        }
    }

    ///Hovering event handler for the marker, reveal Info Window with that marker's store information
    const onMarkerHover = (retailer_location: RetailerLocation): void => {
        setSelectedRetailerLocation(retailer_location);
        setCurrentPosition(retailer_location.location.geography?.toJSON());
    }

    const onInfoWindowClose = () => setCurrentPosition(null);

    ///On clicking the current location marker, set the zoom and center values and create the route.
    const onCurrentLocationClick = (): void => {
        map?.setZoom(15);
        map?.setCenter(props.center ?? center);
    }

    ///Create the drivable route between origin and destination with Google.Maps.Directions API - set to state
    const createRouteBetweenMarkers = (origin: { lat: number, lng: number }, destination: { lat: number, lng: number }): void => {
        if (process.env.REACT_APP_USE_DIRECTIONS_SERVICE === 'true') {
            if (props.locations.length > 0 && map) {
                const directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();
                const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

                //Set bounds for origin and destination
                bounds.extend(origin);
                bounds.extend(destination);
                map.fitBounds(bounds);

                //Request parameters for directions service
                const request: { origin: { lat: number, lng: number }, destination: { lat: number, lng: number }, travelMode: google.maps.TravelMode } = {
                    origin: origin,
                    destination: destination,
                    travelMode: google.maps.TravelMode.DRIVING
                }

                //Call the directions service to return directions data and on the callback, set the directions data and current position in the state
                directionsService.route(request, (result: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus): void => {
                    if (status === google.maps.DirectionsStatus.OK && result !== null) {
                        setDirections(result);
                        setCurrentPosition(destination);
                    } else {
                        console.error(`error fetching directions: ${status}`);
                    }
                });
            }
        }
    }

    return (
        <GoogleMap
            clickableIcons={false}
            mapContainerStyle={props.containerStyle ?? defaultContainerStyle}
            center={props.center ?? center}
            zoom={props.zoom ?? defaultZoomLevel}
            onLoad={onMapLoad}
            onUnmount={onMapUnmount}
            options={mapOptions}
        >
            <MarkerClustererF
                averageCenter
                enableRetinaIcons
                gridSize={60}
            >
                {(clusterer) => (
                    <div className="markers">
                        {props.locations.map((e: RetailerLocation, i: number) => (
                            <MarkerF
                                clusterer={clusterer}
                                key={i}
                                onMouseOver={() => onMarkerHover(e)}
                                onClick={() => onMarkerClick(e)}
                                title={e.retailer.name!}
                                options={markerOptions}
                                animation={google.maps.Animation.DROP}
                                position={{ lat: e.location.geography?.latitude as number, lng: e.location.geography?.longitude as number }}
                            />
                        ))}
                        {(props.userLocation && (
                            <MarkerF
                                onClick={onCurrentLocationClick}
                                position={props.userLocation}
                                options={userMarkerOptions}
                                animation={google.maps.Animation.BOUNCE}
                            />
                        ))}
                    </div>
                )}
            </MarkerClustererF>
            {directions && (
                <PolylineF
                    path={directions.routes[0].overview_path}
                    options={polylineOptions}
                />
            )}
            {current_position && (
                <InfoWindow
                    position={current_position}
                    onCloseClick={onInfoWindowClose}
                    options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                >
                    <InfoWindowBubble
                        location={selected_retailer_location!}
                        product={props.product}
                        sku={props.sku}
                        site={props.site}
                    />
                </InfoWindow>
            )}
        </GoogleMap>
    );
}

export default DealerMap;