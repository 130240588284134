import Socials, {SocialType} from "../../../models/Socials";
import TooltipButton from "../general/TooltipButton";

interface SharePageSectionProps {}

const SharePageSection = (props: SharePageSectionProps) => {
    const socials: SocialType [] = Socials.getSocials();

    return (
        <div className="share-page-links mt-4">
            <h6>Share This Page</h6>
            {socials.map((social: SocialType) => (
                <div key={social.name} className="tooltip-button-wrapper large d-inline-block me-3">
                    <TooltipButton
                        title={social.name}
                        link={social.url}
                        icon={social.icon}
                    />
                </div>
            ))}
        </div>
    );
}

export default SharePageSection;