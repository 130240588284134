import LocationInfoBlock from "./LocationInfoBlock";
import PhonesSection from "./PhonesSection";
import ContactSection from "./ContactSection";
import Phone from "../../../models/Phone";
import ContactUrl from "../../../models/ContactUrl";
import {Col, Container, Row} from "react-bootstrap";
import ContactForm from "./ContactForm";

interface StoreInfoSectionProps {
    addressName: string | null | undefined,
    address1: string | null | undefined,
    address2: string | null | undefined,
    city: string | null | undefined,
    state: string | null | undefined,
    postalCode: string | null | undefined,
    phones: Phone [] | undefined,
    contactUrls: ContactUrl [] | undefined,
    directionsUrl: string | null | undefined,
    picture_url: string | null | undefined,
    picture_alt: string | null | undefined
}

const StoreInfoSection = (props: StoreInfoSectionProps) => {
    return (
        <div className="primary-color-bg text-white p-3 rounded-start-2 h-100 mb-3">
            <button className="pb-3 btn " onClick={() => {window.open(props.picture_url ?? "/", "_blank")}}>
                <img
                    className="img-fluid "
                    src={props.picture_url ?? ""}
                    alt={props.picture_alt ?? ""}
                />
            </button>
            <h3 className="pb-3">Contact Info</h3>
            <LocationInfoBlock
                infoTitle="Address Name"
                infoValue={props.addressName}
            />
            <LocationInfoBlock
                infoTitle="Primary Address"
                infoValue={props.address1}
            />
            <LocationInfoBlock
                infoTitle="Secondary Address"
                infoValue={props.address2}
            />
            <LocationInfoBlock
                infoTitle="City"
                infoValue={props.city}
            />
            <LocationInfoBlock
                infoTitle="State"
                infoValue={props.state}
            />
            <LocationInfoBlock
                infoTitle="Postal Code"
                infoValue={props.postalCode}
            />
            <PhonesSection phones={props.phones}/>
            {(props.directionsUrl) && (
                <a href={props.directionsUrl} target="_blank" className="btn btn-outline-primary mt-5">Get
                    Directions</a>
            )}
        </div>
    );
}

export default StoreInfoSection;