export interface SocialType {
    name: string,
    url: string,
    icon: string
}

class Socials {
    static message: string = "Checkout This Store!";
    static twitter: SocialType = {
        name: "Twitter",
        url: `https://twitter.com/intent/tweet?url=${window.location.href}&text=${encodeURIComponent(this.message)}`,
        icon: "fa-brands fa-square-x-twitter"
    };
    static facebook: SocialType = {
        name: "Facebook",
        url: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
        icon: "fa-brands fa-square-facebook"
    };
    static medium: SocialType = {
        name: "Medium",
        url: `https://medium.com/share?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(this.message)};`,
        icon: "fa-brands fa-medium"
    };
    static reddit: SocialType = {
        name: "Reddit",
        url: `https://www.reddit.com/submit?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(this.message)}`,
        icon: "fa-brands fa-square-reddit"
    };
    static linkedIn: SocialType = {
        name: "LinkedIn",
        url: `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}&title=${encodeURIComponent(this.message)}`,
        icon: "fa-brands fa-linkedin"
    };
    static email: SocialType = {
        name: "Email",
        icon: "fa-solid fa-square-envelope",
        url: `mailto:recipient@example.com?subject=${encodeURIComponent(this.message)}`
    };

    static getSocials(): SocialType [] {
        return [this.twitter, this.facebook, this.medium, this.reddit, this.linkedIn, this.email];
    }
}

export default Socials;