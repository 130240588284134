import Phone from "./Phone";
import ContactUrl from "./ContactUrl";
import Address from "./Address";

class Retailer {
    id: number | null = null;
    name: string | null = null;
    phones: Phone [] = [];
    contact_urls: ContactUrl [] = [];
    addresses: Address [] = [];

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.phones = data.phones.map((p: any) => new Phone(p));
        this.contact_urls = data.contact_urls.map((c: any) => new ContactUrl(c));
        console.log(data);
        this.addresses = data.addresses.map((a: any) => new Address(a));
    }

    flatten(): string {
        const flattened_addresses: string = this.addresses.map((m: Address) => m.flatten()).join(".");
        const flattened_phones: string = this.phones.map((p: Phone) => p.flatten()).join(".");
        const name: string | undefined = this.name?.trim().toLowerCase();
        return `${name}.${flattened_addresses}.${flattened_phones}`;
    }
}

export default Retailer;