declare global {
    interface Date {
        hasItBeenMoreThan24Hours(dateTimeStamp: number): boolean;
    }
}

Date.prototype.hasItBeenMoreThan24Hours = function (dateTimeStamp: number): boolean {
    const current_date: Date = new Date();
    const timeDifference: number = dateTimeStamp - current_date.getTime();
    const millisecondsIn24Hours: number = 24 * 60 * 60 * 1000;

    return timeDifference > millisecondsIn24Hours;
}


export default Date.prototype.hasItBeenMoreThan24Hours;